import request from '@/utils/request'

// 人员选框列表
export function peopleList(data) {
  return request({
    url: '/baseAdmin/common/user-list',
    method: 'post',
    data,
  })
}
// 预存货列表
export function getListData(data) {
  return request({
    url: '/promoteAdmin/goods/index',
    method: 'post',
    data,
  })
}

// 预存货活动修改排序
export function listSort(data) {
  return request({
    url: '/promoteAdmin/goods/sort-save',
    method: 'post',
    data,
  })
}

// 修改保存预存货活动
// export function edit(data) {
//   return request({
//     url: '/promoteAdmin/goods/update',
//     method: 'post',
//     data,
//   })
// }

// 创建预存货活动
export function create(data) {
  return request({
    url: '/promoteAdmin/goods/create',
    method: 'post',
    data,
  })
}

// 修改保存预存货活动
export function edit(data) {
  return request({
    url: '/promoteAdmin/goods/update',
    method: 'post',
    data,
  })
}

// 预存货活动复制
export function copyRow(data) {
  return request({
    url: '/promoteAdmin/goods/copy',
    method: 'post',
    data,
  })
}
// 后台-预存货活动详情
export function rowDetail(data) {
  return request({
    url: '/promoteAdmin/goods/info-detail',
    method: 'post',
    data,
  })
}
// 预存货单位，商品名称..下拉框
export function selectList(data) {
  return request({
    url: '/promoteAdmin/goods-order/detail-list',
    method: 'post',
    data,
  })
}

// 停止活动
export function stopRow(data) {
  return request({
    url: '/promoteAdmin/goods/stop',
    method: 'post',
    data,
  })
}

// 客户预存货单据列表
export function orderList(data) {
  return request({
    url: '/promoteAdmin/goods-order/index',
    method: 'post',
    data,
  })
}

// 客户预存货订单详情
export function clientRowDetail(data) {
  return request({
    url: '/promoteAdmin/goods-order/order-info',
    method: 'post',
    data,
  })
}
// 添加还货订单-提交
export function returnSubmit(data) {
  return request({
    url: '/promoteAdmin/goods-order/return-submit',
    method: 'post',
    data,
  })
}

// 预存货单据审核
export function rowReview(data) {
  return request({
    url: '/promoteAdmin/goods-order/check',
    method: 'post',
    data,
  })
}

// 添加还货订单界面信息
export function returnRowDetail(data) {
  return request({
    url: '/promoteAdmin/goods-order/return-info-back',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {下拉}
 * // 客户预存单据

 */
// 预存货活动&方案下拉框
export function activeSelectList(data) {
  return request({
    url: '/promoteAdmin/goods-order/info-list',
    method: 'post',
    data,
  })
}
// 类型下拉

// 预存货方案下拉框
export function planSelectList(data) {
  return request({
    url: '/promoteAdmin/goods-order/detail-list',
    method: 'post',
    data,
  })
}
// 预存货方案对应表格数据
export function findPlanData(data) {
  return request({
    url: '/promoteAdmin/goods-order/detail-list',
    method: 'post',
    data,
  })
}
/**
 * 预存货还货明细 API
 */

// 预存货还货明细
export function preBackDetail(data) {
  return request({
    url: '/promoteAdmin/goods-order/return-detail',
    method: 'post',
    data,
  })
}

/**
 * 预存货汇总
 */

// 预存货汇总统计
export function preAllDetail(data) {
  return request({
    url: '/promoteAdmin/goods-order/total',
    method: 'post',
    data,
  })
}

// 添加客户预存货
export function addClientPre(data) {
  return request({
    url: '/promoteAdmin/goods-order/add-order',
    method: 'post',
    data,
  })
}
/**
 * @终止活动
 */
export function stopActive(data) {
  return request({
    url: '/promoteAdmin/goods-order/stop',
    method: 'post',
    data,
  })
}
